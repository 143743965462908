<template>
  <div class="leftColumn">
   <div>
      <graphTitle title="车牌绑定情况">
        <el-tooltip placement="top" content="展示统计时间段内发生停车行为的车辆中，被会员绑定的车辆与未被会员绑定的车辆占比情况">
              <i class="el-icon-question my-icon" />
            </el-tooltip>
      </graphTitle>

   </div>
    <div id='pied'
         :style="{height: '260px'}"></div>
    <p style="text-align: center;font-size: 17px;color:rgba(0,0,0,0.65)">已知车辆数量：{{pieData.allPlates || 0}}辆</p>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    pieData: {
      // type: Array,
      default: function () {
        return {}
      }
    }
  },
  name: 'Carbinding',
  components: {
    graphTitle
  },
  data () {
    return {
      calculate: 0,
      pie4: [
        { value: 0, name: '绑定车牌数' },
        { value: 0, name: '未绑定车牌数' }
      ]
    }
  },
  watch: {
    pieData: {
      handler (value) {
        // console.log(value)
        this.pie4 = [];
        this.pie4.push({ value: value.bindPlates, name: '绑定车牌数' }, { value: value.notBindPlates > 0 ? value.notBindPlates : 0, name: '未绑定车牌数' })
        // this.calculate = parseInt(this.pie4[1].value / (this.pie4[0].value + this.pie4[1].value) * 100) + '%'
        if (value.allPlates) {
           this.calculate = value.allPlates ? (this.pie4[0].value / (value.allPlates) * 100).toFixed(2) + '%' : '0.00%';
        } else {
           this.calculate = '0.00%';
        }

        // this.calculate = value.bindPlatesRatio + '%';
        this.setPieD();
      },
      deep: true
    }
  },
  methods: {
    setPieD () {
      // let data = [
      //   {
      //     value: parseInt(100 * Math.random()),
      //     name: '绑定车牌数'
      //   }, {
      //     value: parseInt(100 * Math.random()),
      //     name: '平台采集总车辆数'
      //   }
      // ]
      let option = {
        color: ['#FFA924', '#DDECFD'],
        // title: {
        //   text: '车牌绑定情况',
        //   left: 'left',
        //   top: '0',
        //   padding: [0, 25, 0, 0],
        //   textStyle: {
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          trigger: 'item',
          padding: [15, 15, 15, 30],
          // formatter: "{a}: {c} <br/>占比: {d}%"
          formatter: (params) => {
            // console.log(params)
            let tipText =
              `
								<p>
									<span style="position: absolute;left: 10px;">${params.marker}</span>${params.name}：${params.value}个
								</p>
								<p>
									<span style="position: absolute;left: 10px;">${params.marker}</span>占比：${params.percent}%
								</p>
							`
            return tipText;
          }
        },
        legend: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          data: [{
            name: '绑定车牌数',
            icon: 'circle'
          }, {
            name: '未绑定车牌数',
            icon: 'circle'
          }]
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '105px',
          z: 2,
          zlevel: 100,
          style: {
            text: this.calculate,
            fill: '#000',
            width: 100,
            height: 30,
            font: 'bolder 25px Microsoft YaHei'
          }
        },
        series: [
          {
            center: ['50%', '45%'],
            name: '绑定车牌',
            type: 'pie',
            radius: ['65%', '80%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: false,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                },
                formatter: '{d}%'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: this.pie4
          }
        ]
      };
      let myChart = this.$echarts.init(document.getElementById('pied'));
      myChart.setOption(option);
    }
  },
  created () {
  },
  mounted () {
    // 监听维度变化
    //   this.$bus.$on('mapPageCharts', (type, code) => {
    // 	  this.buttonClick();
    //   })
    this.setPieD();
  }
}
</script>
<style scoped>
</style>
