<template>
  <div>
    <graphTitle title="新增用户趋势图">
      <el-tooltip placement="top" content="展示统计时间段内每日或每月产生的注册会员数量趋势，车牌绑定：统计当天新增绑定车牌的用户数量">
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="circularChart" class="circularChart"></div>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    tableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
    arrearageHistorytableData: {
      default: function () {
        return {};
      },
    },
    temporaryParktableData: {
      default: function () {
        return {};
      },
    },
    nightParktableData: {
      default: function () {
        return {};
      },
    },
    paySummaryReporttableData: {
      default: function () {
        return {};
      },
    },
    type: {
      default: "c1",
    },
  },
  data() {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      // 日期（共用）
      dateList: [],
      // 夜间停车 （以下）
      nightParknameList: [],
      nightParkdataList: [],
      // 营收总报表（以下）
      paySummaryReportdataList: [],
      seriesValue1: [
        {
          name: "应收总额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
        {
          name: "欠费额",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "red",
          },
          itemStyle: {
            normal: {
              color: "red",
            },
          },
        },
      ],
      seriesValue2: [
        {
          name: "当日临停次数",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      seriesValue3: [
        {
          name: "会员注册",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#0000FF",
          },
          itemStyle: {
            normal: {
              color: "#0000FF",
            },
          },
        },
        {
          name: "车牌绑定",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#33FFCC",
          },
          itemStyle: {
            normal: {
              color: "#33FFCC",
            },
          },
        },
        // {
        //   name: '解绑车牌数',
        //   type: 'line',
        //   data: [0, 0, 0, 0, 0, 0, 0, 0],
        //   lineStyle: {
        //     color: '#FF0000'
        //   },
        //   itemStyle: {
        //     normal: {
        //       color: '#FF0000'
        //     }
        //   }
        // }
      ],
      seriesValue4: [
        {
          name: "历史营收趋势",
          type: "line",
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lineStyle: {
            color: "#00FF00",
          },
          itemStyle: {
            normal: {
              color: "#00FF00",
            },
          },
        },
      ],
      textTitle1: "欠费金额趋势",
      textTitle2: "过去30日临时停车趋势",
      // textTitle3: '日间/夜间停车时长趋势',
      textTitle3: "",
      textTitle4: "历史营收趋势",
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    tableData: {
      handler(value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].value * 1;
          this.nameList.push(value[i].dataDate);
          this.dataList.push({
            name: value[i].name,
            value: Number(value[i].value / 100).toFixed(2),
          });
        }
        this.totalNum = (this.totalNum / 100).toFixed(2);
        this.drawGraph();
      },
      deep: true,
    },
    // 欠费历史分析（欠费金额趋势）
    arrearageHistorytableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue1[0].data = [];
        this.seriesValue1[1].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 应收金额
          this.seriesValue1[0].data.push(Number(value[i].shouldPay / 100).toFixed(2));
          // 欠费额
          this.seriesValue1[1].data.push(Number(value[i].arrearageAmount / 100).toFixed(2));
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 临时停车统计（过去30日临时停车趋势）
    temporaryParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue2[0].data = [];
        for (var i = 0; i < value.length; i++) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 过去30日临时停车趋势数据
          this.seriesValue2[0].data.push(value[i].shortParkNum);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        this.totalNum = 0;
        this.nightParknameList = [];
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        // this.seriesValue3[2].data = [];
        // for (var i = 0; i < value.length; i++) {
        for (var i = value.length - 1; i >= 0; i--) {
          //        this.totalNum += value[i].value * 1
          this.dateList.push(value[i].dataDate);
          // 注册人数
          this.seriesValue3[0].data.push(value[i].newUsers);
          // 绑定车牌书
          this.seriesValue3[1].data.push(value[i].bindPlates);
          // 解绑车牌数
          // this.seriesValue3[2].data.push(value[i].unBindPlates);
        }
        this.drawGraph();
      },
      deep: true,
    },
    // 营收总报表的营收趋势
    paySummaryReporttableData: {
      handler(value) {
        this.totalNum = 0;
        let dateArr = [];
        this.dateList = [];
        this.seriesValue4[0].data = [];
        for (var i = 0; i < value.length; i++) {
          // this.totalNum += value[i].value * 1
          dateArr.push(value[i].date);
          this.seriesValue4[0].data.push(Number(value[i].amount / 100).toFixed(2));
        }
        this.dateList = this.uniq(dateArr);
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    // 去重
    uniq(array) {
      var temp = [];
      for (var i = 0; i < array.length; i++) {
        if (temp.indexOf(array[i]) == -1) {
          temp.push(array[i]);
        }
      }
      return temp;
    },
    drawGraph() {
      let that = this;
      let circularChart = this.$echarts.init(document.getElementById("circularChart"));
      let option = {
        title: {
          text:
            that.type == "c1"
              ? that.textTitle1
              : that.type == "c2"
              ? that.textTitle2
              : that.type == "c3"
              ? that.textTitle3
              : that.textTitle4,
        },
        legend: {
          data:
            that.type == "c1"
              ? ["应收总额", "欠费额"]
              : that.type == "c2"
              ? ["当日临停次数"]
              : that.type == "c3"
              ? ["会员注册", "车牌绑定"]
              : ["历史营收趋势"],
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            const res = that.nightParktableData.find((item) => {
              return item.dataDate == params[0].axisValue;
            });
            let tooltip = "";
            // let marker0 = params[0] && params[0].marker ? params[0].marker : "";
            // let marker1 = params[1] && params[1].marker ? params[1].marker : "";
            tooltip += res.dataDate + "</br>";
            params.forEach(e => {
              tooltip += `${e.marker} 净增${e.seriesName}: ${e.value}(个)` + "</br>";
            })
            // if (marker0) {
            //   tooltip += marker0 + `净增会员注册: ${res.newUsers}个` + "</br>";
            // }
            // if (marker1) {
            //   tooltip += marker1 + `净增车牌绑定: ${res.bindPlates}个` + "</br>";
            // }
            // tooltip += `停车记录总数: ${res.allPark}(次)` + "</br>";
            // console.log(res);
            // console.log(params);
            return tooltip;
          },
        },
        toolbox: {
          show: true,
          feature: {
            //		            magicType: {show: true, type: ['stack', 'tiled']},
            //		            saveAsImage: {show: true}
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateList,
        },
        yAxis: {
          type: "value",
          name: "数量(个)",
        },
        series:
          that.type == "c1"
            ? that.seriesValue1
            : that.type == "c2"
            ? that.seriesValue2
            : that.type == "c3"
            ? that.seriesValue3
            : that.seriesValue4,
      };
      circularChart.setOption(option, true);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circularChart
  width 100%
  height 300px
  overflow hidden
</style>
